import { ActionTree } from 'vuex';
import { StateInterface } from '..';
import { StateInterfaceInicial } from './state';
import Vue from 'vue';
// import Vue from 'vue'

const actions: ActionTree<StateInterfaceInicial, StateInterface> = {
	setTheme({ commit }, theme) {
		commit('setTheme', theme);
	},

	setOperacao({ commit }, user) {
		commit('setOperacao', user);
	},

	setCorBorda({ commit }, user) {
		commit('setCorBorda', user);
	},

	setOperacoes({ commit }, user) {
		commit('setOperacoes', user);
	},

	setImprimirRecibo({ commit }, user) {
		commit('setImprimirRecibo', user);
	},

	setQuantidadeVias({ commit }, user) {
		commit('setQuantidadeVias', user);
	},
	setQuantidadeViasPadrao({ commit }, user) {
		commit('setQuantidadeViasPadrao', user);
	},

	setQuantidadeImpressaoRecebimento({ commit }, user) {
		commit('setQuantidadeImpressaoRecebimento', user);
	},
	setQuantidadeImpressaoRecebimentoPadrao({ commit }, user) {
		commit('setQuantidadeImpressaoRecebimentoPadrao', user);
	},

	setModo({ commit }, user) {
		commit('setModo', user);
	},

	setOperacaoCodigo({ commit }, user) {
		commit('setOperacaoCodigo', user);
	},

	setSistemaAtualizado({ commit }, user) {
		commit('setSistemaAtualizado', user);
	},
	setVendaMedicamento({ commit }, user) {
		commit('setVendaMedicamento', user);
	},

	setOperador({ commit }, user) {
		commit('setOperador', user);
	},

	setMensagemFinalizar({ commit }, user) {
		commit('setMensagemFinalizar', user);
	},
	setCnpjTef({ commit }, user) {
		commit('setCnpjTef', user);
	},
	setGrupoEmbalagemRetornavel({ commit }, grupoEmbalagemRetornavel) {
		commit('setGrupoEmbalagemRetornavel', grupoEmbalagemRetornavel);
	},

	setPdv({ commit }, user) {
		commit('setPdv', user);
	},

	setIdentificacao({ commit }, user) {
		commit('setIdentificacao', user);
	},

	setCliente({ commit }, user) {
		commit('setCliente', user);
	},

	setVendedor({ commit }, user) {
		commit('setVendedor', user);
	},

	setPorcentagemMaxAlterarValUn({ commit }, user) {
		commit('setPorcentagemMaxAlterarValUn', user);
	},

	setPermiteAlterarValorUn({ commit }, user) {
		commit('setPermiteAlterarValorUn', user);
	},

	setTipos({ commit }, user) {
		commit('setTipos', user);
	},

	setFrenteLoja({ commit }, user) {
		commit('setFrenteLoja', user);
	},

	setTabelas({ commit }, user) {
		commit('setTabelas', user);
	},

	setToken({ commit }, user) {
		commit('setToken', user);
	},

	setSolicitarVendedor({ commit }, user) {
		commit('setSolicitarVendedor', user);
	},

	setSolicitarVendedorESenha({ commit }, user) {
		commit('setSolicitarVendedorESenha', user);
	},

	setItemCodigoEmbalagensConsultadas({ commit }, user) {
		commit('setItemCodigoEmbalagensConsultadas', user);
	},
	setImpressora({ commit }, user) {
		commit('setImpressora', user);
	},

	changeTabela({ commit }, user) {
		commit('changeTabela', user);
	},

	changeImpressora({ commit }, user) {
		commit('changeImpressora', user);
	},

	changeShowBuscaPessoa({ commit }, user) {
		commit('changeShowBuscaPessoa', user);
	},

	changeFullscreen({ commit }, user) {
		commit('changeFullscreen', user);
	},

	changeDark({ commit }, user) {
		commit('changeDark', user);
	},

	changeServico({ commit }, user) {
		commit('changeServico', user);
	},

	changeModoConsulta({ commit }, user) {
		commit('changeModoConsulta', user);
	},

	changePrimeiroItemVenda({ commit }, user) {
		commit('changePrimeiroItemVenda', user);
	},

	changeOperacaoAberta({ commit }, user) {
		commit('changeOperacaoAberta', user);
	},

	changeCodigoUltimoPedido({ commit }, user) {
		commit('changeCodigoUltimoPedido', user);
	},

	changeTrocoUltimoPedido({ commit }, user) {
		commit('changeTrocoUltimoPedido', user);
	},

	changeQuantidadeItemFixa({ commit }, user) {
		commit('changeQuantidadeItemFixa', user);
	},

	changeIsFiltroBuscaItemCodigo({ commit }, user) {
		commit('changeIsFiltroBuscaItemCodigo', user);
	},
	changeUrlApiGraphql({ commit }, user) {
		commit('changeUrlApiGraphql', user);
	},
	changeUrlImpressao({ commit }, user) {
		commit('changeUrlImpressao', user);
	},
	changeEditandoLinha({ commit }, user) {
		commit('changeEditandoLinha', user);
	},
	changeEstoquePrincipal({ commit }, user) {
		commit('changeEstoquePrincipal', user);
	},
	changeEstadoBotaoSalvar({ commit }, user) {
		commit('changeEstadoBotaoSalvar', user);
	}
};

export default actions;
