import { VERSAO_SISTEMA } from 'src/util/ConstantesUtil';
import { getCurrentInstance } from 'vue';
import { getConfiguracaoTeclas, getSomenteNumero, getValorData, getValorTipado } from '../util/AppUtil';
import ApiGraphql from './Api';
import TerminalVersaoPdvService from './TerminalVersaoPdvService';
import CredenciadoraService from 'src/services/CredenciadoraService';
export default class FrenteLojaService {
	constructor(vue) {
		const app = getCurrentInstance();
		this.$indexDB = vue.$indexDB;
		this.$store = vue.$store;
		this.$updateTeclas = vue.$updateTeclas;
		this.$notificacao = vue.$notificacao;
		this.$getFullScreen = vue.$getFullScreen;
		this.$getNFCeIdentificacao = vue.$getNFCeIdentificacao;
		this.$setNFCeIdentificacao = vue.$setNFCeIdentificacao;
		this.$geralService = vue.$geralService;
		this.headers = app?.appContext.config.globalProperties.$headers;
		this.terminalVersaoPdvService = new TerminalVersaoPdvService();
		this.credenciadoraService = new CredenciadoraService();
	}

	async initFrenteLoja() {
		try {
			await this.setUrlApi();
			await this.setIdentificacao();
			await this.setCliente();
			await this.setVersaoAtualizada();
			await this.setModoDark();
			await this.setCorBorda();
			await this.atualizaFrenteLojaLocal();
			await this.setUtilizaServico();
			await this.setPermiteAlterarValorUn();
			await this.setPorcentagemMaxAlterarValUn();
			await this.setTabInicial();
			await this.setVendaMedicamento();
			await this.setQuantidadeViasImpressao();
			await this.setCnpjTef();
		} catch (error) {
			await this.$notificacao.erro('Erro ao iniciar Frente de Loja');
			throw new Error(error);
		}
	}

	async getFrenteLoja(empresaId, terminalId) {
		const body = {
			query: `
                query {
                        frenteLoja(empresaId:"${empresaId}" terminalId : "${terminalId}"){
							estoquePrincipal {
								id
								nome
    						}
							ncms {
								id
							}
							empresaConfiguracoes{
								valor
								configuracao {
								  id
								  tipoObjeto{
									id
								  }
								}
							 }
							empresa {
								pessoa {
								  id
								  nome
								  nomeAlternativo
								  avatar
								  avatarUrl
								  pessoaJuridica {
									tipoRegimeFiscal {
										id
									}
									cnpj
								  }
								  pessoaTelefones{
									telefone {
									  ddd
									  numero
									}
								  }
								  pessoaEnderecos {
									endereco {
									  uf
									  logradouro
									  numero
									  codigoIbgeUf
									  codigoIbgeCidade
									  cidade
									}
								  }
								  pessoaEnderecoEletronicos {
									enderecoEletronico {
									  enderecoEletronico
									  tipoEnderecoEletronico {
										id
									  }
									}
								  }
								}
								codigo
								nomeCertificado
								codigoJclan
							  }
							  terminal {
								id
								nome
								serieNfce
								numeroTerminalTef
								ativaAutoDesligar
								ativaVenda
								ativaPreVenda
								ativaOrcamento
								finalizaVenda
								finalizaPreVenda
								finalizaOrcamento
								grupoTerminal {
									id
									nome
								}
								balancas {
								  id
								  nome
								  velocidade
								  stopbits
								  tipoModelo {
									id
								  }
								  tipoPorta {
									nome
								  }
								}
								impressoras {
								  id
								  nome
								  abrirGaveta
								  quantidadeCaracterLinha
								  fonteCondensada
								  quantidadeCaracterLinhaCondensada
								  tipoModoImpressao {
									id
								  }
								  tipoImpressora {
									id
								  }
								  tipoModelo {
									nome
									id
								  }
								  tipoPorta {
									nome
								  }
								}
							  }
							  tabelasVenda {
								id
								nome
								aprovacaoSupervisor
								padrao
							  }
							  tipos {
								id
								nome
								sigla
								grupo
							  }
							  formasPagamento {
								id
								nome
								mostrarFrenteLoja
    							numeroMaximoParcela
							prazoMaximoParcela
								valorMinimoParcelamento
								imprimirComprovante
								movimentacaoPagamento { id nome nomeCodigo codigo}
								movimentacaoRecebimento { id nome nomeCodigo codigo}
								formasPagamentosCredenciadorasCartoes {
									credenciadoraCartao {
									  id
									  cnpj
									  nome
									}
								}
								formaPagamentoParcelas {
									id
									nome
									numeroParcelas
									prazoDiasVencimento
									valorMinimoParcelamento
								}
								tipoTroco {
								  id
								  nome
								  sigla
								  grupo
								}
								tipoCarteiraDigital {
								  id
								  nome
								  sigla
								  grupo
								}
								tipoTransacaoAdicionalTef {
								  id
								  nome
								  sigla
								  grupo
								}
								tipoFormaPagamento {
								  id
								  nome
								  sigla
								  grupo
								}
								tipoBandeira {
								  id
								  nome
								  sigla
								  grupo
								}
								tipoOrigem {
								  id
								  nome
								  sigla
								  grupo
								}
								tipoServico {
								  id
								  nome
								  sigla
								  grupo
								}
							  }
                        }
                  }`
		};
		try {
			const result = await ApiGraphql.post('graphql', body, { headers: this.headers() });

			if (result) {
				return result.data.frenteLoja;
			} else {
				throw new Error('Sem Conexão com o Servidor');
			}
		} catch (e) {
			const err = JSON.parse(JSON.stringify(e));
			console.error(err);
			throw e;
		}
	}

	async getFrenteLojaLocalStorage() {
		return await this.$indexDB.get('frenteLoja');
	}

	async setEstoquePrincipal(frenteLoja) {
		const estoquePrincipal = frenteLoja.estoquePrincipal;
		await this.$store.dispatch('geral/changeEstoquePrincipal', estoquePrincipal);
	}

	async atualizaFrenteLojaLocal() {
		const terminalId = await this.$indexDB.get('terminalId');
		const empresaId = await this.$indexDB.get('empresaId');
		const frenteLoja = await this.getFrenteLoja(empresaId, terminalId);
		if (frenteLoja && frenteLoja != null) {
			this.ajustaTipos(frenteLoja);
			this.ajustaFormasPagamento(frenteLoja);
			this.ajustarNcms(frenteLoja);
			this.setImpressora(frenteLoja);
			await this.ajustaEmpresaConfiguracoes(frenteLoja);
			await this.setEstoquePrincipal(frenteLoja);
			const pdv = frenteLoja.terminal.grupoTerminal ? frenteLoja.terminal.grupoTerminal.nome : frenteLoja.terminal.nome;
			const fullScreen = await this.$getFullScreen();
			await this.$indexDB.set('frenteLoja', frenteLoja);
			await this.$store.dispatch('geral/setFrenteLoja', frenteLoja);
			await this.$store.dispatch('geral/setPdv', pdv);
			await this.$store.dispatch('geral/changeFullscreen', fullScreen);
			await this.$store.dispatch('geral/setFrenteLoja', frenteLoja);
		}

		this.$updateTeclas(getConfiguracaoTeclas(frenteLoja));

		await this.atualizaTabelaSelecionada();
	}

	async ajustaEmpresaConfiguracoes(frenteLoja) {
		try {
			const empresaConfiguracoes = frenteLoja.empresaConfiguracoes;
			delete frenteLoja.empresaConfiguracoes;
			frenteLoja.empresaConfiguracoes = [];
			for (let index = 0; index < empresaConfiguracoes.length; index++) {
				const empresaConfAux = empresaConfiguracoes[index];
				if (
					empresaConfAux.configuracao.tipoObjeto.id !== 'CONFIGURACAO_TIPO_OBJETO_STRING' &&
					empresaConfAux.configuracao.tipoObjeto.id !== 'CONFIGURACAO_TIPO_OBJETO_TIPO'
				) {
					if (empresaConfAux.configuracao.tipoObjeto.id === 'CONFIGURACAO_TIPO_OBJETO_DATE') {
						frenteLoja.empresaConfiguracoes[empresaConfAux.configuracao.id] = getValorData(empresaConfAux.valor);
					} else if (empresaConfAux.configuracao.tipoObjeto.id === 'CONFIGURACAO_TIPO_OBJETO_NUMBER') {
						frenteLoja.empresaConfiguracoes[empresaConfAux.configuracao.id] = getValorTipado(
							String(Number(empresaConfAux.valor))
						);
					} else {
						frenteLoja.empresaConfiguracoes[empresaConfAux.configuracao.id] = getValorTipado(empresaConfAux.valor);
					}
				} else {
					frenteLoja.empresaConfiguracoes[empresaConfAux.configuracao.id] = empresaConfAux.valor;
				}
			}
			this.ajustarEtiquetaEmpresa(frenteLoja);
		} catch (error) {
			console.error(error);
		}
	}

	ajustarEtiquetaEmpresa(frenteLoja) {
		const config = frenteLoja.empresaConfiguracoes;
		frenteLoja.etiquetaEmpresa = {};
		frenteLoja.etiquetaEmpresa.etiquetaDescricaoCompleta = config['ETIQUETA_DESCRICAO_COMPLETA'];
		frenteLoja.etiquetaEmpresa.etiquetaPrecoCamuflado = config['ETIQUETA_PRECO_CAMUFLADO'];
		frenteLoja.etiquetaEmpresa.etiquetaDescontoValorTotal = config['ETIQUETA_DESCONTO_VALOR_TOTAL'];
		frenteLoja.etiquetaEmpresa.etiquetaDescontoQuantidadeFundoPreto = config['ETIQUETA_DESCONTO_QUANTIDADE_FUNDO_PRETO'];
		frenteLoja.etiquetaEmpresa.etiquetaMostraReferenciaItemCodigo = config['ETIQUETA_MOSTRA_REFERENCIA_ITEM_CODIGO'];
		frenteLoja.etiquetaEmpresa.etiquetaCamuflagemValor = config['ETIQUETA_CAMUFLAGEM_VALOR'];
		frenteLoja.etiquetaEmpresa.etiquetaCabecalhoPadrao = config['ETIQUETA_CABECALHO_PADRAO'];
		frenteLoja.etiquetaEmpresa.etiquetaEspacamentoEntreEtiqueta = config['ETIQUETA_ESPACAMENTO_ENTRE_ETIQUETA'];
		frenteLoja.etiquetaEmpresa.etiquetaMostraValor = config['ETIQUETA_MOSTRA_VALOR'];
		frenteLoja.etiquetaEmpresa.etiquetaRodapePadrao = config['ETIQUETA_RODAPE_PADRAO'];
		frenteLoja.etiquetaEmpresa.etiquetaEspacamentoTopo = config['ETIQUETA_ESPACAMENTO_TOPO'];
		frenteLoja.etiquetaEmpresa.etiquetaNomeEmpresa = config['ETIQUETA_NOME_EMPRESA'];
		frenteLoja.etiquetaEmpresa.etiquetaPosicaoInicialPrimeiraEtiqueta = config['ETIQUETA_POSICAO_INICIAL_PRIMEIRA_ETIQUETA'];
	}

	ajustaFormasPagamento(frenteLoja) {
		const formasPagamento = frenteLoja.formasPagamento;
		delete frenteLoja.formasPagamento;
		frenteLoja.formasPagamentoGrupo = {};
		for (let index = 0; index < formasPagamento.length; index++) {
			const formaPagamentoAx = formasPagamento[index];
			if (!frenteLoja.formasPagamentoGrupo[formaPagamentoAx.tipoFormaPagamento.id]) {
				if (formaPagamentoAx.mostrarFrenteLoja) {
					frenteLoja.formasPagamentoGrupo[formaPagamentoAx.tipoFormaPagamento.id] = [];
				}
			}
			if (formaPagamentoAx.mostrarFrenteLoja) {
				frenteLoja.formasPagamentoGrupo[formaPagamentoAx.tipoFormaPagamento.id].push(formaPagamentoAx);
			}
		}
	}

	ajustaTipos(frenteLoja) {
		const tipos = frenteLoja.tipos;
		delete frenteLoja.tipos;
		frenteLoja.tipoGrupos = {};
		frenteLoja.tiposMap = new Map();
		for (let index = 0; index < tipos.length; index++) {
			const tipoAx = tipos[index];
			if (!frenteLoja.tipoGrupos[tipoAx.grupo]) {
				frenteLoja.tipoGrupos[tipoAx.grupo] = {};
			}
			frenteLoja.tipoGrupos[tipoAx.grupo][tipoAx.id] = tipoAx;
			frenteLoja.tiposMap.set(tipoAx.id, tipoAx);
		}
	}

	ajustarNcms(frenteLoja) {
		const ncms = frenteLoja.ncms;
		frenteLoja.ncmsMap = new Map();
		for (let index = 0; index < ncms.length; index++) {
			const ncmAx = ncms[index];
			frenteLoja.ncmsMap.set(ncmAx.id, ncmAx);
		}
		delete frenteLoja.ncms;
	}

	async atualizaTabelaSelecionada() {
		let tabela = await this.$indexDB.get('tabela');
		const frenteLoja = this.$store.state.geral.frenteLoja;
		if (!tabela) {
			tabela = this.getTabelaPadrao();
		} else {
			let tabelaTemp = frenteLoja.tabelasVenda.find((tb) => tb.id === tabela.id);
			if (tabelaTemp === undefined || tabelaTemp === null) {
				tabela = this.getTabelaPadrao();
			} else {
				tabela = tabelaTemp;
			}
		}
		this.$store.dispatch('geral/changeTabela', tabela);
	}

	getTabelaPadrao() {
		const frenteLoja = this.$store.state.geral.frenteLoja;
		let tabelaTemp = frenteLoja.tabelasVenda.find((tabela) => tabela.padrao === true);
		if (tabelaTemp === undefined || tabelaTemp === null) {
			tabelaTemp = frenteLoja.tabelasVenda[0];
		}
		return tabelaTemp;
	}

	async setModoDark() {
		let dark = await this.$indexDB.get('dark');
		if (dark === undefined || dark === null) dark = false;
		if (process.env.MODE === 'electron') {
			window.windowAPI.setThemeDefault(dark);
		}
		await this.$store.dispatch('geral/changeDark', dark);
		await this.$indexDB.set('dark', dark);
		this.$geralService.setTheme(this.$store.state.geral.dark);
	}

	async setCnpjTef() {
		try {
			const credenciadoraCartao = await this.credenciadoraService.getCredenciadoTef();
			await this.$store.dispatch('geral/setCnpjTef', credenciadoraCartao.cnpj);
		} catch (error) {
			console.error(error);
		}
	}

	async setCorBorda() {
		let corBorda = await this.$indexDB.get('corBorda');
		if (corBorda === undefined || corBorda === null) corBorda = 'corAzul';
		await this.$store.dispatch('geral/setCorBorda', corBorda);
	}

	async setUtilizaServico() {
		let servico = this.$store.state.geral.frenteLoja.empresaConfiguracoes.UTILIZA_SERVICO ?? false;
		await this.$store.dispatch('geral/changeServico', servico);
		await this.$indexDB.set('servico', servico);
	}

	async setPermiteAlterarValorUn() {
		const permiteAlterarValorUn = this.$store.state.geral.frenteLoja.empresaConfiguracoes.PERMITIR_ALTERAR_VALOR_UNITARIO ?? false;
		await this.$indexDB.set('permiteAlterarValorUn', permiteAlterarValorUn);
		await this.$store.dispatch('geral/setPermiteAlterarValorUn', permiteAlterarValorUn);
	}

	async setPorcentagemMaxAlterarValUn() {
		const porcentagemMaxAlterarValUn =
			this.$store.state.geral.frenteLoja.empresaConfiguracoes.PORCENTAGEM_MAXIMA_ALTERAR_VALOR_UNITARIO ?? 2;
		await this.$indexDB.set('porcentagemMaxAlterarValUn', porcentagemMaxAlterarValUn);
		await this.$store.dispatch('geral/setPorcentagemMaxAlterarValUn', porcentagemMaxAlterarValUn);
	}

	async setTabInicial() {
		await this.$store.dispatch('geral/setModo', 'preVenda');
	}

	async setUrlApi() {
		const urlApi = await this.$indexDB.get('GRAPHQL_API_URL');
		let urlImpressao = await this.$indexDB.get('REST_API_URL');
		if (!urlImpressao) urlImpressao = 'http://localhost:3334';
		await this.$store.dispatch('geral/changeUrlImpressao', urlImpressao);
		if (urlApi) {
			await this.$store.dispatch('geral/changeUrlApiGraphql', urlApi);
			if (process.env.MODE === 'electron') {
				window.windowAPI.setUrlApi(urlApi);
			}
		}
	}

	async setIdentificacao() {
		const identificacao = await this.$getNFCeIdentificacao();
		if (!identificacao) {
			await this.$setNFCeIdentificacao('Não identificado');
			await this.$store.dispatch('geral/setIdentificacao', 'Não identificado');
		} else {
			await this.$store.dispatch('geral/setIdentificacao', identificacao);
		}
	}

	async setCliente() {
		const cliente = await this.$indexDB.get('cliente');
		await this.$store.dispatch('geral/setCliente', cliente);
	}

	async setVendaMedicamento() {
		const vendaMedicamento = this.$store.state.geral.frenteLoja.empresaConfiguracoes.HABILITAR_VENDA_MEDICAMENTO ?? false;
		await this.$store.dispatch('geral/setVendaMedicamento', vendaMedicamento);
	}
	async setQuantidadeViasImpressao() {
		const quantidadeViasPadrao = this.$store.state.geral.frenteLoja.empresaConfiguracoes.RECIBO_QUANTIDADES_VIAS ?? 1;
		await this.$store.dispatch('geral/setQuantidadeViasPadrao', quantidadeViasPadrao);
	}
	async setImpressora(frenteLoja) {
		const impressora = await this.$indexDB.get('impressora');
		if (!impressora && frenteLoja.terminal && frenteLoja.terminal.impressoras && frenteLoja.terminal.impressoras.length > 0) {
			await this.$store.dispatch('geral/setImpressora', frenteLoja.terminal.impressoras[0]);
		} else if (frenteLoja.terminal && frenteLoja.terminal.impressoras && frenteLoja.terminal.impressoras.length > 0) {
			const impressoraResult = frenteLoja.terminal.impressoras.find((imp) => imp.id === impressora.id);
			if (impressoraResult) {
				await this.$store.dispatch('geral/setImpressora', impressoraResult);
			} else {
				await this.$store.dispatch('geral/setImpressora', frenteLoja.terminal.impressoras[0]);
			}
		} else {
			await this.$store.dispatch('geral/setImpressora', null);
		}
	}

	async setVersaoAtualizada() {
		const terminalId = await this.$indexDB.get('terminalId');
		const terminalVersaoPdv = await this.terminalVersaoPdvService.getTerminalVersaoPdv(terminalId);
		const versaoApp = await this.terminalVersaoPdvService.retornarVersaoApp();
		if (terminalVersaoPdv) {
			if (getSomenteNumero(terminalVersaoPdv.versaoPdv) !== getSomenteNumero(VERSAO_SISTEMA)) {
				await this.terminalVersaoPdvService.atualizarTerminalVersaoPdv(terminalId);
			}
			if (getSomenteNumero(VERSAO_SISTEMA) < getSomenteNumero(versaoApp.versao)) {
				await this.$store.dispatch('geral/setSistemaAtualizado', false);
			} else {
				await this.$store.dispatch('geral/setSistemaAtualizado', true);
			}
		} else {
			await this.terminalVersaoPdvService.inserirTerminalVersaoPdv(terminalId);
		}
	}
}
